import React from 'react';

function Signature(props) {
	const SignatureTemplate = props.data.defaultTemplate

	return (
		<div className="signature-container">
			<div className="signature-wrapper">
				{props.data.isCopied &&
					<div className="copied-container"><div className="copied-wrapper"><p>Copied</p></div></div>
				}
				<SignatureTemplate data={props.data} />
			</div>
		</div>
	);
}

export default Signature;
