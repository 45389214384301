import React from 'react';

function Qsa(props) {
	const color = "#0054A6"
	const tableSize = 500
	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{padding:'5px 5px 5px 5px',backgroundColor:'#ffffff',width:tableSize+'px',fontFamily:'Montserrat, Arial, sans-serif',lineHeight:'11pt'}}>
		  		<tbody> 
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={255} style={{width:'255px',verticalAlign:'top'}}>

					    <table width={255} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'12.5pt',fontFamily:'Montserrat, Arial, sans-serif'}}>
					      	<tbody>
					      	<tr height={15} style={{lineHeight:'13pt'}}>
					        	<td colSpan="2" style={{lineHeight:'13pt'}}>
					        		<p style={{lineHeight:'13pt',fontSize:'11.5pt',fontWeight:'bold',margin: '0px',color:color}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}
					        		</p>
					        		<p style={{lineHeight:'12pt',fontSize:'8.5pt',margin: '0px',color:'#2F3233',fontWeight: '500'}}>{!props.data.position ? 'Position' :props.data.position}
						        	</p>
					        	</td>
					        </tr>
					      	<tr height={15} style={{lineHeight:'12pt',fontSize:'8pt'}}><td></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/qsa/img/2021/icon-tel-2021.png" alt="Tel icon" style={{width:'12px'}} /></td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233',fontSize:'8pt'}}>&nbsp;&nbsp;<span>{props.data.telNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center',color:'#fff'}}>&nbsp;</td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233',fontSize:'8pt'}}>&nbsp;&nbsp;<span>{props.data.telNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center'}}><img width={7} height={12} src="https://artanholding.com/signature_img/qsa/img/2021/icon-phone-2021.png" alt="Phone icon" style={{width:'7px'}} /></td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233',fontSize:'8pt'}}>&nbsp;&nbsp;<span>{props.data.phoneNumber}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center'}}>&nbsp;</td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233',fontSize:'8pt'}}>&nbsp;&nbsp;<span>{props.data.phoneNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/qsa/img/2021/icon-fax-2021.png" alt="Fax icon" style={{width:'12px'}} /></td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233',fontSize:'8pt'}}>&nbsp;&nbsp;<span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{lineHeight:'12pt'}}>
					        	<td width={15} style={{textAlign:'center'}}><img width={12} height={9} src="https://artanholding.com/signature_img/qsa/img/2021/icon-email-2021.png" alt="Email icon" style={{width:'12px'}} /></td>
					        	<td width={240} style={{padding:'0px',color:'#2F3233'}}>&nbsp;&nbsp;<a href={"mailto:"+props.data.emailAddress} style={{textDecorationLine:'none',color:'#2F3233',fontSize:'8pt'}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					    	
					        <tr valign="top" style={{lineHeight:'0pt'}}>
					        	<td width={15} height={20} valign="top" style={{textAlign:'center',verticalAlign:'top'}}>
								<span style={{fontSize:'6pt',lineHeight: '2pt',fontWeight:'normal',color:'#2F3233',margin:'0',padding:'0'}}>&nbsp;</span><br/>

								<img width={12} height={12} src="https://artanholding.com/signature_img/qsa/img/2022/icon-map.png" alt="Map icon" style={{width:'12px'}} /></td>
					        	<td width={250} valign="top" style={{padding:'0px',color:'#2F3233'}}>
					        	<p style={{color:'#2F3233',fontSize:'8pt',lineHeight:'12pt',margin:'0px'}}>&nbsp;&nbsp;
									{props.data.officeLocation ? props.data.officeLocation :'13th Floor, The Excellence Tower, '}
								</p>
								<p style={{color:'#2F3233',fontSize:'8pt',lineHeight:'12pt',margin:'0px'}}>&nbsp;&nbsp;
									{props.data.addressLocation ? props.data.addressLocation :'West Bay'}{props.data.countryLocation ? ', '+props.data.countryLocation :', Doha, Qatar'}
								</p>
								<p style={{color:'#2F3233',fontSize:'8pt',lineHeight:'12pt',margin:'0px'}}>&nbsp;&nbsp;P.O. Box {props.data.poboxLocation ? props.data.poboxLocation :'200197'}
								</p>
								</td>
					        </tr>
		  					</tbody>
					    </table>
					</td>
					<td width={10} style={{borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={20} style={{width:'30px',color:'#ffffff'}}>&nbsp;</td>
					<td width={165} style={{width:'155px', padding:'0px',verticalAlign:'middle',textAlign:'center'}}>
						
						<img width={120} height={120} src={"https://artanholding.com/signature_img/qsa/img/qr/"+props.data.firstName.toLowerCase().replaceAll(" ", "-")+ "-" + props.data.lastName.toLowerCase().replaceAll(" ", "-") +".png"} alt="QR Code" role="presentation" style={{width:'120px',maxWidth:'120px',height:'120px',margin:'0',padding:'0'}} /><br/>
						<p style={{color:'#ffffff',fontSize:'10pt',lineHeight:'12pt',margin:'0px'}}>&nbsp;</p>
						<table cellPadding={0} cellSpacing={0} style={{verticalAlign:'middle',fontFamily:'Montserrat, Arial, sans-serif',textAlign:'center',margin: 'auto'}}>
					      	<tbody>
					    	<tr valign="middle" style={{verticalAlign:'middle'}}>
					    		<td width={24} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.facebook.com/QatarSkillsAcademy" target="_blank" rel="noopener noreferrer" style={{padding:'0',margin:'0',textDecorationLine:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/qsa/img/2022/icon-fb.png" alt="icon-fb.png"/>
					                </a>
					    		</td>
					    		<td width={24} valign="middle" style={{verticalAlign:'middle'}}>
					    		 	<a href="https://twitter.com/qatarskillsacad" target="_blank" rel="noopener noreferrer" style={{padding:'0',margin:'0',textDecorationLine:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/qsa/img/2022/icon-tt.png" alt="icon-twitter.png"/>
					                </a>
					    		</td>
					    		<td width={24} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.instagram.com/qatarskillsacad/" rel="noopener noreferrer" target="_blank" style={{padding:'0',margin:'0',textDecorationLine:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/qsa/img/2022/icon-ig.png" alt="icon-ig.png"/>
					                </a>
					    		</td>
					    		<td width={24} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.linkedin.com/company/qatarskillsacademy" rel="noopener noreferrer" target="_blank" style={{padding:'0',margin:'0',textDecorationLine:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/qsa/img/2022/icon-in.png" alt="icon-in.png"/>
					                </a>
					    		</td>
					    		<td width={25} valign="middle" style={{verticalAlign:'middle'}}>
					    			<span style={{fontSize:'8.5pt',lineHeight: '2pt',fontWeight:'normal',color:'#0054A6',margin:'0',padding:'0'}}>qtr.skills</span><br/>
					    		</td>
					    	</tr>
		  					</tbody>
					    </table>
						<p style={{color:'#ffffff',fontSize:'3.75pt',lineHeight:'3pt',margin:'0px'}}>&nbsp;</p>

						{ (props.data.webAddress) &&
							<p style={{color:color,fontSize:'8.5pt',lineHeight:'12pt',margin:'0px'}}>
								<a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecorationLine:'none',color:'#0054A6',fontSize:'8.5pt'}}>{props.data.webAddress}</a>
							</p>
						}
						
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'8pt',fontSize:'6pt',fontFamily:'Montserrat, Arial, sans-serif',textAlign: 'justify' }}>
					<td colSpan={4} style={{verticalAlign:'top',textAlign: 'justify'}}>
						<span style={{fontSize:'7pt',color:color,margin:'0',padding:'0'}}>&nbsp;</span><br/>
						<img width={tableSize} height={154} src="https://artanholding.com/signature_img/qsa/img/2023/QS_email_signature_2023.png" alt="banner" role="presentation" style={{maxWidth:tableSize+'px',height:'154px',margin:'0',padding:'0'}} /><br/>
						<span style={{fontSize:'7pt',color:color,margin:'0',padding:'0'}}>&nbsp;</span><br/>
						<span style={{fontSize:'6pt',fontWeight:'normal',color:'#111111',margin:'0',padding:'0',textAlign: 'justify'}}>This email, together with any attachments, is intended for the named recipient(s) only and may contain privileged and confidential information. If received in error, please inform the sender as quickly as possible and delete this email and any copies from your computer network. If not an intended recipient of this email, you must not copy, distribute or rely on it, and any form of disclosure, modification, distribution and/or publication of this email is prohibited. Unless stated otherwise, this email represents only the views of the sender and not the views of Qatar Skills Training Centre.</span><br/><br/>
						<span style={{fontSize:'6pt',fontWeight:'normal',color:'#111111',margin:'0',padding:'0',textAlign: 'justify'}}>WARNING: Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Qatar Skills Training Centre accepts no liability for any damage caused by any virus transmitted by this email.</span>
						{/*Ramadan Banner
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={500} height={119} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-qstc.png" alt="Eid 2023" />
						*/}
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Qsa;
