import React from 'react';

function Hai(props) {
	const color = "#185672"
	const tableSize = 400
	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:tableSize+'px',fontFamily:'Calibri,Arial',lineHeight:'11pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={165} style={{verticalAlign:'top',lineHeight:'6.75pt'}}>
						<img width={165} height={42} src="https://artanholding.com/signature_img/bnitc/img/bnitc-logo.png" alt="logo" role="presentation" style={{width:'165px',maxWidth:'165px',height:'42px',margin:'0',padding:'0'}} /><br/>
						<img width={165} height={51} src="https://artanholding.com/signature_img/hai/usgbc-logo-2.png" alt="usgbclogo" role="presentation" style={{width:'165px',maxWidth:'165px',height:'51px',margin:'0px',padding:'0'}}/>
						<p style={{color:color,fontSize:'6.75pt',lineHeight:'11pt',margin:'0px'}}>{props.data.officeLocation ? props.data.officeLocation :'Office 4, Floor 37'}</p>
					   <p style={{color:color,fontSize:'6.75pt',lineHeight:'11pt',margin:'0px'}}>{props.data.addressLocation ? props.data.addressLocation :'Palm Tower B, West Bay'}</p>
					   <p style={{color:color,fontSize:'6.75pt',lineHeight:'11pt',margin:'0px'}}>P.O. Box {props.data.poboxLocation ? props.data.poboxLocation :'9310'}{props.data.countryLocation ? ', '+props.data.countryLocation :', Doha, Qatar'}</p>
					</td>
					<td width={10} style={{borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={10} style={{width:'10px',color:'#ffffff'}}>&nbsp;</td>
					<td width={215} style={{padding:'0px',verticalAlign:'top'}}>
					    <table width={215} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'7.5pt',fontFamily:'Calibri,Arial'}}>
					      	<tbody>
					      	<tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan="2">
					        		<p style={{lineHeight:'11pt',fontSize:'10.5pt',margin: '0px',color:color,fontWeight:'bold'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<p style={{lineHeight:'11pt',fontSize:'8.25pt',margin: '0px',color:color}}>{!props.data.position ? 'Position' :props.data.position}</p>
					        		<p style={{lineHeight:'11pt',fontSize:'8.25pt',margin: '0px',color:color}}>{!props.data.department ? 'Department' :props.data.department}</p>
					        	</td>
					        </tr>
					      	<tr height={25} style={{lineHeight:'11pt',fontSize:'7.5pt'}}><td></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-tel.png" alt="Tel icon" style={{width:'12px'}} /></td>
					        	<td width={190} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.telNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center',color:'#fff'}}>&nbsp;</td>
					        	<td width={190} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.telNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-phone.png" alt="Phone icon" style={{width:'12px'}} /></td>
					        	<td width={190} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.phoneNumber}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}>&nbsp;</td>
					        	<td width={190} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.phoneNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-fax.png" alt="Fax icon" style={{width:'12px'}} /></td>
					        	<td width={190} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-email.png" alt="Email icon" style={{width:'12px'}} /></td>
					        	<td width={190} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'7.5pt'}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-web.png" alt="Web icon" style={{width:'12px'}} /></td>
					        	<td width={190} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'7.5pt'}}>{props.data.webAddress}</a></td>
					        </tr>
					        }
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'8pt',fontSize:'6.75pt'}}>
					<td colSpan={4} style={{verticalAlign:'top'}}>
						<p style={{lineHeight:'8pt',margin: '0px'}}>
							<span style={{fontSize:'6.75pt',fontStyle:'italic',margin:'0',color:'#185672'}}>BN International & Trading & Contracting, member of Artan Holding group of companies:</span><br/>
							<img width={400} height={51} src="https://artanholding.com/signature_img/hai/banner-company.png" alt="usgbclogo" role="presentation" style={{margin:'0',padding:'0'}} /><br/>
							<span style={{fontSize:'5.25pt',fontStyle:'italic',fontWeight:'normal',color:'#7F7F7F',margin:'0',padding:'0'}}>This email, together with any attachments, is intended for the named recipient(s) only and may contain privileged and confidential information. If received in error, please inform the sender as quickly as possible and delete this e-mail and any copies from your computer network. If not an intended recipient of this email, you must not copy, distribute or rely on it and any form of disclosure of the sender and not the views of BN International & Trading & Contracting. WARNING: Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Artan Holding accepts no liability for any damage caused by any virus by this email</span>
						</p>
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Hai;
