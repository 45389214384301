import React from 'react';

function GarafahCleaning(props) {
	const color = "#790026"
	const font = "Calibri, Arial, sans-serif"
	const font2 = "Times New Roman, Arial, sans-serif"
	const tableSize = 350

	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:tableSize+'px',fontFamily:font,lineHeight:'11pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={150} style={{textAlign:'left',verticalAlign:'top',lineHeight:'7pt'}}>
						<img width={150} height={34} src="https://artanholding.com/signature_img/garafahcleaning/algarafahcleaning-logo.png" alt="logo" role="presentation" style={{width:'150px',maxWidth:'150px',height:'34px',margin: '0px',padding: '0'}}/>
					</td>
					<td width={10} style={{borderRight:'solid 1px #111111',color:'#ffffff'}}>&nbsp;</td>
					<td width={20} style={{width: '10px',color:'#ffffff'}}>&nbsp;</td>
					<td width={180} style={{padding:'0px',verticalAlign:'top',lineHeight:'11pt'}}>
					    <table width={180} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					      	<tbody>
					      	<tr height={12} style={{lineHeight:'11pt'}}>
					        	<td height={12} colSpan="2" style={{lineHeight:'11pt',fontFamily:font}}>
					        		<p style={{margin:'0px',padding:'0px',color:'#111111',fontSize:'13pt',fontWeight:'bold',fontFamily: font,lineHeight:'13pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<span style={{padding:'0px',color:color,fontSize:'8.5pt',fontWeight:'bold'}}>{!props.data.position ? 'Position' :props.data.position}</span>
					        	</td>
					        </tr>
					      	<tr height={5} style={{lineHeight:'5pt',fontSize:'4.5pt'}}><td colSpan={2}></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color,fontSize:'8.5pt',fontFamily:font2,fontWeight:'bold'}}><span>T.</span></td>
					        	<td width={155} style={{width:'155px',padding:'0px',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}><span>{props.data.telNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color,fontSize:'8.5pt',fontFamily:font2,fontWeight:'bold'}}><span>&nbsp;</span></td>
					        	<td width={155} style={{width:'155px',padding:'0px',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}><span>{props.data.telNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color,fontSize:'8.5pt',fontFamily:font2,fontWeight:'bold'}}><span>M.</span></td>
					        	<td width={155} style={{width:'155px',padding:'0px',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}><span>{props.data.phoneNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color,fontSize:'8.5pt',fontFamily:font2,fontWeight:'bold'}}><span>&nbsp;</span></td>
					        	<td width={155} style={{width:'155px',padding:'0px',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}><span>{props.data.phoneNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color,fontSize:'8.5pt',fontFamily:font2,fontWeight:'bold'}}><span>F.</span></td>
					        	<td width={155} style={{width:'155px',padding:'0px',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}><span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan={2} width={155} style={{width:'155px',padding:'0px',color:'rgb(0,0,0)'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'underline',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan={2} width={155} style={{width:'155px',padding:'0px',color:'rgb(0,0,0)'}}><a href={'http://'+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'underline',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}>{props.data.webAddress}</a>
					        	</td>
					        </tr>
					    	}
					      	<tr height={5} style={{lineHeight:'5pt',fontSize:'4.5pt'}}><td colSpan={2}></td></tr>
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan="2"  width={274} style={{width:'274px',textAlign:'left',color:'#111111',fontSize:'8.5pt',fontFamily:font2}}>
					        	<span>{props.data.addressLocation ? props.data.addressLocation :'West Bay'}</span>
					        	<span>{props.data.countryLocation ? ', ' +props.data.countryLocation :', Doha'}</span>
					        	</td>
					        </tr>
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize: '6.5pt'}}>
					<td colSpan={4} style={{verticalAlign:'top',fontFamily:font}}>
						<span style={{fontSize:'6.5pt',color:'#111111',margin:'0',padding:'0',fontWeight:'bold'}}>WARNING: </span>
						<span style={{fontSize:'6.5pt',color:'#111111',margin:'0',padding:'0'}}>Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Al Garafah Cleaning W.L.L accepts no liability for any damage caused by any virus transmitted by this email.</span>
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default GarafahCleaning;
