import React from 'react';

function Cc(props) {
	const color = "#1D1D1B"
	const font = "Arial, sans-serif"
	const tableSize = 337.5
	props.data.webAddress = "www.ulsteruniversity.qa"

	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:tableSize+'pt',fontFamily:font,lineHeight:'9pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'9pt'}}>  			
					<td style={{textAlign:'left',verticalAlign:'top',lineHeight:'9pt'}}>
						<img width={220} height={109} src="https://artanholding.com/signature_img/citycollege/img/CUCLogo2022.png" alt="logo" role="presentation" style={{width:'220px',maxWidth:'220px',height:'109px',margin: '0',padding: '0'}} />
					</td>
				</tr>
				<tr height={25}><td></td></tr>
				<tr height={12} style={{lineHeight:'13pt',fontSize:'13pt'}}>
		        	<td height={12} colSpan="2" style={{lineHeight:'13pt',fontFamily:font,fontSize:'13pt'}}>
		        		<span style={{padding:'0px',color:color,fontSize:'13pt',fontWeight:'bold',fontFamily: font}}>{!props.data.firstName ? 'Name' :props.data.firstName} {!props.data.lastName ? 'Surname' :props.data.lastName}</span>
		        	</td>
		        </tr>
				<tr height={12} style={{lineHeight:'12pt'}}>
		        	<td height={12} colSpan="2" style={{lineHeight:'12pt',fontFamily:font}}>
		        		<span style={{padding:'0px',color:color,fontSize:'9.75pt'}}>{!props.data.position ? 'Position' :props.data.position}</span><br/>
		        		{ (props.data.telNumber) &&
		        		<span style={{padding:'0px',color:color,fontSize:'9.75pt'}}><b style={{padding:'0px',color:color,fontSize:'9.75pt'}}>T: </b>{props.data.telNumber}</span>
		        		}
		        		{ (props.data.telNumber) && <br/>}
		        		{ (props.data.emailAddress) &&
		        		<span style={{padding:'0px',color:color,fontSize:'9.75pt'}}>
		        			<b style={{padding:'0px',color:color,fontSize:'9.75pt'}}>E: </b>
		        			<a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'9.75pt',fontFamily:font}}>{props.data.emailAddress}</a>
		        		</span>
		        		}
		        		{ (props.data.webAddress) && <span style={{color:'#ffffff'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
		        		{ (props.data.webAddress) &&
		        		<span style={{padding:'0px',color:color,fontSize:'9.75pt'}}>
		        			<b style={{padding:'0px',color:color,fontSize:'9.75pt'}}>W: </b>
		        			<a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'9.75pt'}}>{props.data.webAddress}</a>
		        		</span>
		        		}
		        	</td>
		        </tr>
		  		</tbody>
			</table>
		</div>
	);
}

/*tr height={12} style={{lineHeight:'12pt'}}>
    <td height={12} colSpan="2" style={{lineHeight:'12pt',fontFamily:font}}>
    <br/>
    <a href="http://ulsteruniversity.qa/" target="_Blank" style={{color:'transparent'}}>
    	<img width={tableSize} height={133} src="https://artanholding.com/signature_img/ulster/img/uu_admissions_open_email_signature.png" alt="logo" role="presentation" style={{width:tableSize+'pt',maxWidth:tableSize+'pt',height:'133px',margin: '0',padding: '0'}} />
    </a>
    </td>
</tr*/

export default Cc;
