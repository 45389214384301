import React from 'react';

function Aces(props) {
	const color = "#1192A3"
	const font = "Montserrat, Arial, sans-serif"
	const tableSize = 470

	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:tableSize+'px',fontFamily:font,lineHeight:'11pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={150} style={{textAlign:'left',verticalAlign:'top',lineHeight:'11pt'}}>
						<img width={150} height={55} src="https://artanholding.com/signature_img/aces/aces-logo.png" alt="logo" role="presentation" style={{width:'150px',maxWidth:'150px',height:'55px',margin: '0px',padding: '0'}}/>
					</td>
					<td width={10} style={{borderRight:'solid 1px #111111',color:'#ffffff'}}>&nbsp;</td>
					<td width={10} style={{width: '10px',color:'#ffffff'}}>&nbsp;</td>
					<td width={300} style={{padding:'0px',verticalAlign:'top',lineHeight:'11pt'}}>
					    <table width={300} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					      	<tbody>
					      	<tr height={12} style={{lineHeight:'11pt'}}>
					        	<td height={12} style={{lineHeight:'11pt',fontFamily:font}}>
					        		<p style={{margin:'0px',padding:'0px',color:'#111111',fontSize:'9.8pt',fontWeight:'600',fontFamily: font,lineHeight:'11pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<span style={{padding:'0px',color:'#111111',fontSize:'8.5pt'}}>{!props.data.position ? 'Position' :props.data.position}</span>
					        	</td>
					        </tr>
					      	<tr height={15}><td colSpan="2"></td></tr>
					      	
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan={2} width={300} style={{width:'300px',textAlign:'left',color:'#111111',fontSize:'8.5pt',fontFamily:font}}>
					        	{ (props.data.telNumber) &&
					        		<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        			<b>T.</b>&nbsp;&nbsp;{props.data.telNumber}
					        			{ (!props.data.telNumber2nd) &&  
					        				<span>&nbsp;&nbsp;&nbsp;</span> 
					        			}
					        			{ (props.data.telNumber2nd) && 
					        				<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        				&nbsp;/&nbsp;{props.data.telNumber2nd} <br/>
					        				</span>
					        			}
					        		</span>
					        	}
					        	{ (props.data.phoneNumber) &&
					        		<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        			<b>M.</b>&nbsp;{props.data.phoneNumber}

					        			{ (!props.data.phoneNumber2nd) &&  
					        				<span>&nbsp;&nbsp;&nbsp;</span> 
					        			}
					        			{ (props.data.phoneNumber2nd) && 
					        				<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        				&nbsp;/&nbsp;{props.data.phoneNumber2nd} <br/>
					        				</span>
					        			}
					        			{ (props.data.telNumber && !props.data.phoneNumber2nd) && <br/> }
					        		</span>
					        	}
					        	{ (props.data.faxNumber) &&
					        		<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        			<b>F.</b>&nbsp;&nbsp;{props.data.faxNumber}&nbsp;&nbsp;&nbsp;&nbsp;
					        			{ (!props.data.telNumber && !props.data.phoneNumber) ? '' :
					        				<span>
						        			{ (!props.data.telNumber) && <br/> }
						        			{ (!props.data.phoneNumber) && <br/> }
						        			</span>
					        			}
					        		</span>
					        	}
					        	{ (props.data.emailAddress) &&
					        		<span style={{color:'#111111',fontSize:'8.5pt',fontFamily:font,lineHeight:'11pt'}}>
					        			<b>E.</b>&nbsp;&nbsp;{props.data.emailAddress}&nbsp;&nbsp;&nbsp;&nbsp;
					        		</span>
					        	}
					        	</td>
					        </tr>
					        <tr height={12}>
					        	<td />
					        </tr>
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={300} style={{width:'300px',textAlign:'left',color:'#111111',fontSize:'8.5pt',fontFamily:font}}>
						        	<span>{props.data.officeLocation ? props.data.officeLocation+ ', ' :'Palm Tower B, Floor 37, '}</span>
						        	<span>{props.data.poboxLocation ? 'PO Box '+props.data.poboxLocation+ ', ' :'PO Box 19379, '}</span>
						        	<span>{props.data.addressLocation ? props.data.addressLocation+ ', ' :'West Bay, '}</span><br/>
						        	<span>{props.data.countryLocation ? props.data.countryLocation :'Doha, Qatar'}</span>
					        	</td>
					        </tr>

					    	{ (props.data.webAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan={2} width={300} style={{width:'300px',padding:'0px',color:color}}><a href={'http://'+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'8.5pt',fontFamily:font,fontWeight:'bold'}}>{props.data.webAddress}</a>
					        	</td>
					        </tr>
					    	}
		  					</tbody>
					    </table>
				  </td>
				</tr>
				{/*
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize: '6.5pt'}}>
					<td colSpan={4} style={{verticalAlign:'top',fontFamily:font}}>
						<span style={{fontSize:'6.5pt',color:'#111111',margin:'0',padding:'0'}}><b>WARNING:</b> Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Artan Consulting and Educational Services accept no liability for any damage caused by any virus transmitted by this email.</span>
					
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={600} height={250} src="https://artanholding.com/signature_img/aces/2023/aces-email-banner.png" alt="Email Banner" />
					
					</td>
				</tr>
				*/}
		  		</tbody>
			</table>
		</div>
	);
}

export default Aces;
