import React from 'react';

function Dbswk(props) {
	const color = "#1D1D1B"
	return (
		<div className="signature-content" id="signature-content">
			<table width={500} cellPadding={0} cellSpacing={0} style={{width:'500px',fontFamily:'Helvetica-Light, Arial, sans-serif',lineHeight:'9pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'9pt'}}>  			
					<td width={133} style={{verticalAlign:'top',lineHeight:'6.75pt'}}>
						<img width={93} height={159} src="https://artanholding.com/signature_img/dbs/img/logo-wakra.png" alt="logo" role="presentation" style={{width:'93px',maxWidth:'93px',height:'159px',margin:'0',padding:'0'}} />
					</td>
					<td width={1} style={{borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={39} style={{width:'30px',color:'#ffffff'}}>&nbsp;</td>
					<td width={327} style={{padding:'0px',verticalAlign:'top'}}>
					    <table width={327} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'7.5pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}>
					      	<tbody>
					      	<tr height={30} style={{lineHeight:'10pt'}}>
					        	<td style={{fontFamily:'Helvetica-Light, Arial, sans-serif'}}>
					        		<p style={{margin:'0px',padding:'0px',color:color,fontSize:'13pt',fontWeight:'bold',lineHeight:'13pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<p style={{margin:'0px',padding:'0px',color:color,fontSize:'10pt',lineHeight:'10pt'}}>{!props.data.position ? 'Position' :props.data.position}</p>
					        	</td>
					        </tr>
					      	<tr height={10}><td></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><span style={{fontWeight:'700'}}>T</span>&nbsp;&nbsp;<span>{props.data.telNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><span style={{fontWeight:'700',color:'#fff'}}>T</span>&nbsp;&nbsp;<span>{props.data.telNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><span style={{fontWeight:'700'}}>M</span>&nbsp;<span>{props.data.phoneNumber}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><span style={{fontWeight:'700',color:'#fff'}}>M</span>&nbsp;<span>{props.data.phoneNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><span style={{fontWeight:'700'}}>F</span>&nbsp;&nbsp;<span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}} ><span style={{fontWeight:'700'}}>E</span>&nbsp;&nbsp;<a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'10pt'}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr height={25} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,verticalAlign:'bottom',fontFamily:'Helvetica-Light, Arial, sans-serif'}}><a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'10pt',fontWeight:'700',fontFamily:'Helvetica-Light, Arial, sans-serif'}}>{props.data.webAddress}</a></td>
					        </tr>
					        }
					        <tr height={18} style={{lineHeight:'10pt'}}>
					        	<td style={{padding:'0px',color:color,fontSize:'10pt',fontFamily:'Helvetica-Light, Arial, sans-serif'}}>{props.data.officeLocation ? props.data.officeLocation :'Doha British School Wakra'}{props.data.countryLocation ? ', '+props.data.countryLocation :', Al Wakrah, Qatar'}</td>
					        </tr>
					        <tr height={45}>
					        	<td style={{padding:'0px',color:'#ffffff',fontSize:'10pt',verticalAlign:'middle'}}>
					        	<img width={327} height={45} src="https://artanholding.com/signature_img/dbs/img/email_wk_2024.png" alt="logos" />
					        	</td>
					        </tr>
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px',borderTop:'1px solid' + color}}>
					</td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize:'6.75pt'}}>
					<td colSpan={4} style={{verticalAlign:'top',fontFamily:'Helvetica-Light, Arial, sans-serif'}}>
					<span style={{fontSize:'7.5pt',fontWeight:'normal',color:color,margin:'0',padding:'0'}}>This email, together with any attachments, is intended for the named recipient(s) only and may contain privileged and confidential information. If received in error, please inform the sender as quickly as possible and delete this email and any copies from your computer network. If not an intended recipient of this email, you must not copy, distribute or rely on it, and any form of disclosure, modification, distribution and/or publication of this email is prohibited. Unless stated otherwise, this email represents only the views of the sender and not the views of Doha British School LLC. WARNING: Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Doha British School LLC accepts no liability for any damage caused by any virus transmitted by this email.</span>
					<br/>
					<span style={{fontSize:'7.5pt',fontWeight:'normal',color:color,margin:'0',padding:'0'}}>Please consider the environment before printing this email</span>
					{/*Ramadan Banner
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={500} height={119} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-dbs.png" alt="Eid 2023" />
					*/}</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Dbswk;
