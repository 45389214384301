import React from 'react';

function InputBox(props) {
  return (
	<div className="form-group">
	    <input className={props.data.firstName && 'not-empty'} type="text" name={props.name} onChange={props.onChange} />
	    <label htmlFor={props.name}>{props.text}</label>
  	</div>
  );
}

export default InputBox;
