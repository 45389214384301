import React from 'react';

function Header() {
  return (
    <div className="header-container p-5">
    	<h1>Signature Generator</h1>
		<p>Use the panel on the left to customize your signature, then click the button below to copy the HTML</p>
    </div>
  );
}

export default Header;
