import React from 'react';
import InputBox from './InputBox'
import TemplateButton from './TemplateButton'

function Sidebar(props) {
  const styles = {
  	display: 'block'
  }

  return (
    <div className="sidebar-container">
      <ul className="sidebar-menu">
      	<li><input type="submit" name="templateContainer" value="Template" onClick={props.handleChange} /></li>
      	<li><input type="submit" name="infoContainer" value="Info" onClick={props.handleChange} /></li>
      </ul>
      <div className="infoContainer side-wrapper" style={(props.data.menu === 'infoContainer') ? styles : {} }>
	      	<div className="label">
	      		<p className="mb-5">Enter the info you&apos;d like to be displayed in your signature</p>
	      	</div>
	      	<p className="upper">Details:</p>
	      	<InputBox data={props.data} name="firstName" onChange={props.handleChange} text="First Name"/>
	      	<InputBox data={props.data} name="lastName" onChange={props.handleChange} text="Last Name"/>
	      	<InputBox data={props.data} name="position" onChange={props.handleChange} text="Position"/>
	      	<InputBox data={props.data} name="department" onChange={props.handleChange} text="Department"/>

	      	<p className="upper">Contact Details:</p>
	      	<InputBox data={props.data} name="telNumber" onChange={props.handleChange} text="Tel. Number"/>
	      	<InputBox data={props.data} name="telNumber2nd" onChange={props.handleChange} text="Tel. Number #2"/>
	      	<InputBox data={props.data} name="phoneNumber" onChange={props.handleChange} text="Phone Number"/>
	      	<InputBox data={props.data} name="phoneNumber2nd" onChange={props.handleChange} text="Phone Number #2"/>
	      	<InputBox data={props.data} name="faxNumber" onChange={props.handleChange} text="Fax Number"/>
	      	<InputBox data={props.data} name="emailAddress" onChange={props.handleChange} text="Email Address"/>
	      	<InputBox data={props.data} name="webAddress" onChange={props.handleChange} text="Website"/>

	      	<p className="upper">Address:</p>
	      	<InputBox data={props.data} name="officeLocation" onChange={props.handleChange} text="Office Location"/>
	      	<InputBox data={props.data} name="addressLocation" onChange={props.handleChange} text="Address"/>
	      	<InputBox data={props.data} name="poboxLocation" onChange={props.handleChange} text="P.O. Box"/>
	      	<InputBox data={props.data} name="countryLocation" onChange={props.handleChange} text="City, Country"/>
      </div>
      <div className="templateContainer side-wrapper" style={(props.data.menu === 'templateContainer') ? styles : {} }>
	      	<div className="label">
	      		<p className="mb-5">Choose the signature you&apos;d want to create {props.data.menu}</p>
	      	</div>
	      	<TemplateButton templateKey="ah" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="AH" />
	      	<TemplateButton templateKey="dbs" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="DBS" />
	      	<TemplateButton templateKey="dbsak" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="DBS Ain Khaled" />
	      	<TemplateButton templateKey="dbswk" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="DBS Al Wakra" />
	      	<TemplateButton templateKey="dbsrw" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="DBS Rawdat" />
	      	<TemplateButton templateKey="hai" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="HAI" />
	      	<TemplateButton templateKey="cc" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="CUC" />
	      	<TemplateButton templateKey="qsa" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="QSA" />
	      	<TemplateButton templateKey="bnd" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="BN Design" />
	      	<TemplateButton templateKey="bnitc" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="BNITC" />
	      	<TemplateButton templateKey="aces" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="ACES" />
	      	<TemplateButton templateKey="primepower" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="PPME" />
	      	<TemplateButton templateKey="mipc" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="MIPC" />
	      	<TemplateButton templateKey="mipcfh" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="MIPC Fox Hills" />
	      	<TemplateButton templateKey="gulfcms" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="Gulf CMS" />
	      	<TemplateButton templateKey="uu" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="UU" />
	      	<TemplateButton templateKey="gc" onChange={props.handleChange} chosenTemplateName={props.data.chosenTemplateName} label="Garafah Cleaning" />
      </div>
    </div>
  );
}

export default Sidebar;
