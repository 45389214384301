import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import CopyToClipboard from 'react-copy-html-to-clipboard';
import htmlDocx from 'html-docx-js/dist/html-docx';
import saveAs from 'file-saver';
import juice from 'juice';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Signature from './components/Signature';
import CompanyData from './components/CompanyData';
import ArtanHolding from './components/signature/ArtanHolding';

const findComponent = key => c => {
    return c.code === key 
}

class App extends Component {
  constructor(){
    super()
    this.state = {
      firstName : "",
      lastName : "",
      position : "",
      department : "",
      telNumber : "+974 0000 0000",
      telNumber2nd : "",
      phoneNumber : "+974 0000 0000",
      phoneNumber2nd : "",
      faxNumber : "+974 4412 3799",
      supportNumber : "",
      emailAddress : "my.email@company.com",
      webAddress : "www.artanholding.com",
      officeLocation : "",
      addressLocation : "",
      poboxLocation : "",
      cityLocation : "",
      countryLocation : "",
      upperBanner : "",
      lowerBanner : "",
      upperSize : "",
      lowerSize : "",
      defaultTemplate : ArtanHolding,
      chosenTemplateName : "ah",
      signatureTemplate : CompanyData,
      isCopied: false,
      menu: "templateContainer"
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
  }

  handleCopy() {
    this.setState({
      isCopied : true
    },() => {
      setTimeout(function() {
        this.setState({isCopied: false});
      }.bind(this),2000);
    })
    
    return document.getElementById("signature-content").innerHTML
  }

  handleConvert(e) {
    const {name} = e.target
    const contentDocument = document.getElementById("signature-content").innerHTML
    var html_document = '<!DOCTYPE html><html><head><title></title>'
    html_document  += '</head><body>' + contentDocument + '</body></html>'
    const content = juice(html_document);

    const converted = htmlDocx.asBlob(content, {orientation: 'landscape'});

    saveAs(converted, name+'_signature.docx')
  }

  handleConvertHTML(e){
    const {name} = e.target
    const contentDocument = document.getElementById("signature-content").innerHTML
    var html_document = '<!DOCTYPE html><html><head><title></title>'
    html_document  += '</head><body>' + contentDocument + '</body></html>'

    let data = new Blob([html_document], {type: 'data:attachment/text,'});
    saveAs(data, name+'_signature.html')
  }

  handleChange(e) {
    const {name,value,type} = e.target
    this.setState(prevState => {
        if(type === 'radio') {
          let chosenTemplate = this.state.signatureTemplate.filter(findComponent(value))[0].signatureComponent
          let webAddress = this.state.signatureTemplate.filter(findComponent(value))[0].webAddress
          let telNumber = this.state.signatureTemplate.filter(findComponent(value))[0].telNumber
          let telNumber2nd = this.state.signatureTemplate.filter(findComponent(value))[0].telNumber2nd
          let faxNumber = this.state.signatureTemplate.filter(findComponent(value))[0].faxNumber
          let supportNumber = this.state.signatureTemplate.filter(findComponent(value))[0].supportNumber
          let poboxLocation = this.state.signatureTemplate.filter(findComponent(value))[0].poboxLocation
          return {
            defaultTemplate : chosenTemplate,
            chosenTemplateName : value,
            webAddress : webAddress,
            telNumber : telNumber,
            telNumber2nd : telNumber2nd,
            faxNumber : faxNumber,
            supportNumber : supportNumber,
            poboxLocation : poboxLocation,
          }
        } else if(type === 'submit') {
          return {
            menu: name
          }
        }

        return {
          [name] : value
        }
    }, () => {
      /*if(document.getElementById("myImg1")) {
        const uSize = document.getElementById("myImg1").height;
        this.setState({
          upperSize : uSize
        })
      }
      if(document.getElementById("myImg2")) {
        const lSize = document.getElementById("myImg2").height;
        this.setState({
          lowerSize : lSize
        })
      }*/
    })
  }

  render(){
    return (
      <div className="main-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 p-0">
              <Sidebar data={this.state} handleChange={this.handleChange} />
            </div>
            <div className="col-md-9 p-0 text-center content-wrapper">
              <Header />
              <Signature data={this.state} />

              <br/>
              {/*<CopyToClipboard text={this.handleCopy} options={{asHtml: true}} >
                <button className="btn btn-primary mt-5">Copy signature to clipboard</button>
              </CopyToClipboard>
              <br/>*/}
              <button className="btn btn-primary mt-1" onClick={this.handleConvertHTML} name={this.state.chosenTemplateName}>Convert to HTML File</button>
              <br/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
