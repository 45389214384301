import React from 'react';

function ArtanHolding(props) {
	const color = "#0054A6"
	return (
		<div className="signature-content" id="signature-content">
			<table width={405} cellPadding={0} cellSpacing={0} style={{maxWidth:'405px',width:'405px',fontFamily:'Calibri,Arial',lineHeight:'11pt'}}>
				<tbody>
				<tr style={{lineHeight:'11pt'}}>
					<td width={170} style={{maxWidth:'170px',width:'170px',verticalAlign:'top',lineHeight:'11px'}}>
						<img width={92} height={49} src="https://artanholding.com/signature_img/artanholding/artan-logo.png" alt="Portrait" role="presentation" style={{maxWidth:'92', display:'block'}} />
						<p style={{color:'#ffffff',fontSize:'8.25pt',lineHeight:'11pt',margin:'0px'}}>&nbsp;</p>
						<p style={{color:color,fontSize:'7.75pt',lineHeight:'11pt',margin:'0px'}}>
							{props.data.officeLocation ? props.data.officeLocation :'Office 4, Floor 37'}
						</p>
						<p style={{color:color,fontSize:'7.75pt',lineHeight:'11pt',margin:'0px'}}>{props.data.addressLocation ? props.data.addressLocation :'Palm Tower B, West Bay'}</p>
						<p style={{color:color,fontSize:'7.75pt',lineHeight:'11pt',margin:'0px'}}>P.O. Box {props.data.poboxLocation ? props.data.poboxLocation :'19379'}{props.data.countryLocation ? ', '+props.data.countryLocation :', Doha, Qatar'}</p>
						<p style={{color:'#ffffff',fontSize:'8.25pt',lineHeight:'11pt',margin:'0px'}}>&nbsp;</p>
					</td>
					<td width={10} style={{borderRight:'solid 1px #5b9bd5',color:'#ffffff'}}>&nbsp;</td>
					<td width={15} style={{width:'15px',color:'#ffffff'}}>&nbsp;</td>
					<td width={210} style={{maxWidth:'210px',width:'210px',padding:'0px', verticalAlign:'top'}}>
						<table width={210} cellPadding={0} cellSpacing={0} style={{maxWidth:'210px',width:'210px',verticalAlign:'top',fontSize:'7.5pt',fontFamily:'Calibri,Arial'}}>
							<tbody>
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td colSpan="2">
								<p style={{lineHeight:'11pt',fontSize:'10.5pt',margin: '0px',color:color,fontWeight:'bold'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
								<p style={{lineHeight:'11pt',fontSize:'8.25pt',margin: '0px',color:color}}>{!props.data.position ? 'Position' :props.data.position}</p>
								<p style={{lineHeight:'11pt',fontSize:'8.25pt',margin: '0px',color:color}}>{!props.data.department ? 'Department' :props.data.department}</p>
								</td>
							</tr>
							<tr height={15} style={{lineHeight:'11pt',fontSize:'7.5pt'}}><td></td></tr>
							{(props.data.telNumber) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-tel.png" alt="Tel icon" style={{width:'12px'}} /></td>
								<td width={185} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.telNumber}</span></td>
							</tr>
							: null }
							{(props.data.telNumber && props.data.telNumber2nd) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}>&nbsp;</td>
								<td width={185} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.telNumber2nd}</span></td>
							</tr>
							: null }
							{(props.data.phoneNumber) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-phone.png" alt="Phone icon" style={{width:'12px'}} /></td>
								<td width={185} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.phoneNumber}</span></td>
							</tr>
							: null }
							{(props.data.phoneNumber && props.data.phoneNumber2nd) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}>&nbsp;</td>
								<td width={185} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.phoneNumber2nd}</span></td>
							</tr>
							: null }
							{(props.data.faxNumber) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-fax.png" alt="Fax icon" style={{width:'12px'}} /></td>
								<td width={185} style={{padding:'0px',color:color,fontSize:'7.5pt'}}><span>{props.data.faxNumber}</span></td>
							</tr>
							: null }
							{(props.data.emailAddress) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-email.png" alt="Email icon" style={{width:'12px'}} /></td>
								<td width={185} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'7.5pt'}}>{props.data.emailAddress}</a>
								</td>
							</tr>
							: null }
							{(props.data.webAddress) ?
							<tr height={15} style={{lineHeight:'11pt'}}>
								<td width={25} style={{textAlign:'center'}}><img width={12} height={12} src="https://artanholding.com/signature_img/artanholding/icon-web.png" alt="Web icon" style={{width:'12px'}} /></td>
								<td width={185} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'7.5pt'}}>{props.data.webAddress}</a></td>
							</tr>
							: null }
							</tbody>
						</table>
					</td>
				</tr>
				
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'8pt',fontSize:'5.25pt'}}>
					<td colSpan={4} style={{verticalAlign:'top'}}>
						{/*<p style={{lineHeight:'8pt',margin: '0px'}}>
						<span style={{fontSize:'5.25pt',fontWeight:'bold',margin:'0',padding:'0',color:'#000000'}}>WARNING:</span><br/>
						<span style={{fontSize:'5.25pt',fontWeight:'normal',color:'#7F7F7F',margin:'0',padding:'0'}}>Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Artan Holding accepts no liability for any damage caused by any virus by this email.</span>
						</p>
						Ramadan Banner
						<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
						<img width={405} height={101} src="https://artanholding.com/signature_img/ramadan/2024/ramadan_banner_2024.jpg" alt="Ramadan 2024" />
						*/}
					</td>
				</tr>
				
				</tbody>
			</table>
		</div>
	);
}

export default ArtanHolding;
