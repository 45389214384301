import React from 'react';

function TemplateButton(props) {
  return (
  	<div>
	  	<input type="radio" name="template" value={props.templateKey} onChange={props.onChange} checked={props.chosenTemplateName === props.templateKey}/>
  		<label>{props.label}</label>
  	</div>
  );
}

export default TemplateButton;
