import React from 'react';

function MirageFH(props) {
	//const color = "#0054A6"
	const tableSize = 455
	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:tableSize+'px',fontFamily:'Arial',lineHeight:'11pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={150} style={{verticalAlign:'top',lineHeight:'6.75pt'}}>
						<p style={{lineHeight:'12pt',fontSize:'10pt',margin: '0px',padding:'0px',color:"#111111",fontWeight:'bold'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
		        		<p style={{lineHeight:'10pt',fontSize:'8pt',margin: '0px',padding:'0px',color:"#727071"}}>{!props.data.position ? 'Position' :props.data.position}</p>
		        		<p style={{lineHeight:'6pt',fontSize:'6pt',margin:'0px',padding:'0px'}}>&nbsp;</p>
						<table cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontFamily:'Arial',textAlign:'left',width:'100%'}}>
					      	<tbody>
					        { (props.data.phoneNumber) &&
					       	<>
					        <tr height={15} style={{lineHeight:'11pt',verticalAlign:'middle'}}>
					        	<td colSpan={1} width={20} style={{textAlign:'center',verticalAlign:'middle',}}><img width={8} height={12} src="https://artanholding.com/signature_img/mipc/img/2021/icon-mobile-2021.png" alt="Phone icon" style={{width:'8px',marginRight: '8px'}} /></td>
					        	<td colSpan={5} width={135} style={{padding:'0px',color:"#111111",fontSize:'7pt',verticalAlign:'middle'}}>
					        	<span>{props.data.phoneNumber}</span>
					        	</td>
					        </tr>
					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={6} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <>
					        <tr height={15} style={{verticalAlign:'middle'}}>
					        	<td colSpan={1} width={20} style={{lineHeight:'0pt',textAlign:'center'}}>&nbsp;</td>
					        	<td colSpan={5} width={135} style={{lineHeight:'11pt',padding:'0px',color:"#111111",fontSize:'7pt',verticalAlign:'middle'}}>
					        	<span>{props.data.phoneNumber2nd}</span>
					        	</td>
					        </tr>
					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={6} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					        }
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{verticalAlign:'middle'}}>
					        	<td colSpan={1} width={20} style={{lineHeight:'0pt',textAlign:'left',verticalAlign:'middle'}}><img width={12} height={9} src="https://artanholding.com/signature_img/mipc/img/2021/icon-email-2021.png" alt="Email icon" style={{width:'12px'}} /></td>
					        	<td colSpan={5} width={140} style={{lineHeight:'11pt',padding:'0px',color:'rgb(0,0,0)'}}>
					        	<a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:'#111111',fontSize:'7pt',verticalAlign:'middle'}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
		  					</tbody>
					    </table>
					    <p style={{fontSize:'8pt',margin:'0px',padding:'0px',height:'20px',lineHeight:'20pt'}}>&nbsp;</p>
					    <table cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontFamily:'Arial',textAlign:'left',width:'100%'}}>
					      	<tbody>
					    	<tr valign="middle" style={{verticalAlign:'middle'}}>
					    		<td width={150} valign="top" style={{verticalAlign:'top'}}>
					    			<img width={150} height={51} src="https://artanholding.com/signature_img/mipc/img/2024/mipc-logo-2024.jpg" alt="icon-cert.png"/>
					    		</td>
					    	</tr>
		  					</tbody>
					    </table>
					</td>
					<td width={40} style={{borderRight:'solid 1px #c5c3c4',color:'#ffffff'}}>&nbsp;</td>
					<td width={40} style={{width:'40px',color:'#ffffff'}}>&nbsp;</td>
					<td width={245} style={{padding:'0px',verticalAlign:'top'}}>
					    <table width={245} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontFamily:'Arial'}}>
					      	<tbody>
					      	<tr height={24} style={{lineHeight:'11pt'}}>
					        	<td colSpan="2">
					        		<img width={150} height={24} src="https://artanholding.com/signature_img/mipc/img/2021/logo-mipc-2021.png" alt="logo" role="presentation" style={{width:'150px',maxWidth:'150px',height:'24px',margin:'0',padding:'0'}} />
					        	</td>
					        </tr>
					      	<tr height={25} style={{lineHeight:'11pt',fontSize:'7pt'}}><td></td></tr>
					      	{ (props.data.officeLocation || props.data.addressLocation || props.data.poboxLocation) &&
					      	<>
					        <tr style={{lineHeight:'11pt'}}>
					        	<td width={20} style={{textAlign:'center',verticalAlign:'top'}}><img width={12} height={12} src="https://artanholding.com/signature_img/mipc/img/2021/icon-address-2021.png" alt="Loc icon" style={{width:'12px'}} /></td>
					        	<td width={225} style={{padding:'0px',color:"#111111",fontSize:'7pt'}}>

									<p style={{color:"#111111",fontSize:'6.75pt',lineHeight:'11pt',margin:'0px'}}>{props.data.officeLocation ? props.data.officeLocation+',' :'Artan Residence Fox Hills 150, Building No. 150'}</p>
								    <p style={{color:"#111111",fontSize:'6.75pt',lineHeight:'11pt',margin:'0px'}}>{props.data.addressLocation ? props.data.addressLocation :'Street 218, Zone 69'}{props.data.countryLocation ? ', '+props.data.countryLocation :', Lusail, Qatar'}</p>
					        	</td>
					        </tr>
					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={2} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					        }
					        { (props.data.telNumber) &&
					        <>
					        <tr>
					        	<td width={20} style={{lineHeight:'0pt',textAlign:'center',verticalAlign:'middle'}}><img width={12} height={12} src="https://artanholding.com/signature_img/mipc/img/2021/icon-phone-2021.png" alt="Tel icon" style={{width:'12px'}} /></td>
					        	<td width={225} style={{lineHeight:'11pt',padding:'0px',color:"#111111",verticalAlign:'middle'}}>
					        		<span style={{fontSize:'7pt'}}>{props.data.telNumber}</span>
					        	</td>
					        </tr>
					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={2} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					      	<>
					        <tr>
					        	<td width={20} style={{lineHeight:'0pt',textAlign:'center',color:'#fff',verticalAlign:'middle'}}>&nbsp;</td>
					        	<td width={225} style={{lineHeight:'11pt',padding:'0px',color:"#111111",verticalAlign:'middle'}}>
					        		<span style={{fontSize:'7pt'}}>{props.data.telNumber2nd}</span>
					        	</td>
					        </tr>

					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={2} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					        }
					        { (props.data.faxNumber) &&
					        <>
					        <tr>
					        	<td width={20} style={{lineHeight:'0pt',textAlign:'center',verticalAlign:'middle'}}><img width={8} height={11} src="https://artanholding.com/signature_img/mipc/img/2021/icon-fax-2021.png" alt="Fax icon" style={{width:'8px'}} /></td>
					        	<td width={225} style={{lineHeight:'11pt',padding:'0px',color:"#111111",verticalAlign:'middle'}}>
					        		<span style={{fontSize:'7pt'}}>{props.data.faxNumber}</span>
					        	</td>
					        </tr>

					        <tr height={2} style={{lineHeight:'2pt'}}>
					        	<td colspan={2} style={{lineHeight:'2pt',fontSize:'2pt',margin:'0px',padding:'0px',color:'#ffffff'}}>&nbsp;</td>
					        </tr>
					        </>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr>
					        	<td width={20} style={{lineHeight:'0pt',textAlign:'center',verticalAlign:'middle'}}><img width={12} height={12} src="https://artanholding.com/signature_img/mipc/img/2021/icon-site-2021.png" alt="Web icon" style={{width:'12px'}} /></td>
					        	<td width={225} style={{lineHeight:'11pt',padding:'0px',color:'rgb(0,0,0)'}}>
					        		<a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:'#111111',fontSize:'7pt',verticalAlign:'middle'}}>{props.data.webAddress}</a>
					        	</td>
					        </tr>
					        }
					        <tr height={15} style={{lineHeight:'11pt',fontSize:'7pt'}}><td></td></tr>
		  					</tbody>
					    </table>
					     <table width={85} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontFamily:'Arial',textAlign:'left'}}>
					      	<tbody>
					    	<tr valign="middle" style={{verticalAlign:'middle'}}>
					    		<td width={20} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.facebook.com/miragepropertyqatar/" target="_blank" rel="noopener noreferrer" style={{padding:'0',margin:'0',textDecoration:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/mipc/img/icon-fb-2022.png" alt="icon-fb.png"/>
					                </a>
					    		</td>
					    		<td width={20} valign="middle" style={{verticalAlign:'middle'}}>
					    		 	<a href="https://twitter.com/MirageProperty" target="_blank" rel="noopener noreferrer" style={{padding:'0',margin:'0',textDecoration:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/mipc/img/icon-tt-2022.png" alt="icon-twitter.png"/>
					                </a>
					    		</td>
					    		<td width={20} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.instagram.com/miragepropertyqatar/" rel="noopener noreferrer" target="_blank" style={{padding:'0',margin:'0',textDecoration:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/mipc/img/icon-ig-2022.png" alt="icon-ig.png"/>
					                </a>
					    		</td>
					    		<td width={20} valign="middle" style={{verticalAlign:'middle'}}>
					    			<a href="https://www.linkedin.com/company/miragepropertyqatar" rel="noopener noreferrer" target="_blank" style={{padding:'0',margin:'0',textDecoration:'none',color:'#ffffff'}}>
					                  <img width={18} height={18} src="https://artanholding.com/signature_img/mipc/img/icon-in-2022.png" alt="icon-in.png"/>
					                </a>
					    		</td>
					    	</tr>
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'0pt',fontSize:'6pt'}}>
					<td colSpan={4} style={{verticalAlign:'top'}}>
							<hr/>
							<p style={{fontSize:'9pt',margin:'0',padding:'0',color:'#111111',lineHeight:'11pt'}}><b>Proud Member of <a href="http://www.artanholding.com" style={{textDecoration:'none',color:'#111111'}}>Artan Holding</a> Group of Companies</b></p>
							<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
							<p style={{fontSize:'6pt',fontWeight:'normal',color:'#221e20',margin:'0',padding:'0',lineHeight:'9pt'}}>Email Disclaimer:</p>
							<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
 							<p style={{fontSize:'6pt',fontWeight:'normal',color:'#221e20',margin:'0',padding:'0',lineHeight:'8pt'}}>The information contained in this email is confidential and may be legally privileged. It is solely intended for the named addressee. Any use or access to this email by anyone else is deemed unauthorized by sender. If you are not the named addressee, you should immediately notify the sender, and permanently delete this email, its contents and/or any attachments thereto. Any disclosure, copying, transfer or dissemination is strictly prohibited, and may be considered unlawful. Any views, or opinions in this email are solely those of the sender, and do not necessarily represent those of Mirage International Property Consultants LLC.</p>
							<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
 							<p style={{fontSize:'6pt',fontWeight:'normal',color:'#221e20',margin:'0',padding:'0',lineHeight:'8pt'}}>Warning: Although Mirage International Property Consultants LLC has taken all reasonable precautions to ensure no viruses are present in this email of Mirage International Property Consultants LLC, shall not accept any form of liability for any loss, damage or other claims that may arise from the usage of this email or attachments.</p>
							<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
							{/*Ramadan Banner
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={455} height={108} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-mipc.png" alt="Eid 2023" />
					*/}</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default MirageFH;
