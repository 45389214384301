import React from 'react';

function BnDesign(props) {
	const color = "#111111"
	return (
		<div className="signature-content" id="signature-content">
			<table width={350} cellPadding={0} cellSpacing={0} style={{maxWidth:'350px',width:'350px',fontFamily:'Calibri, Arial',lineHeight:'9pt'}}>
		  		<tbody>
				<tr>  			
					<td width={145} style={{maxWidth:'145px',width:'145px',verticalAlign:'top'}}>
						<table width={145} cellPadding={0} cellSpacing={0} style={{maxWidth:'145px',width:'145px',verticalAlign:'top',fontFamily:'Calibri, Arial',lineHeight:'16px'}}>
					      	<tbody>
					      	<tr height={15} style={{lineHeight:'10pt'}}>
					        	<td colspan="2">
					        		<p height={15} style={{height:'15px',padding:'0px',margin: '0px',color:color,fontSize:'11pt',fontWeight:'bold',lineHeight:'11pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>{!props.data.position ? 'Position' :props.data.position}</p>
					        		{(props.data.department) &&
					        		<p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{!props.data.department ? 'Department' :props.data.department}</p>
					        		}
					        	</td>
					        </tr>
					        <tr height={2} style={{lineHeight:'3pt',fontSize:'3.5pt'}}><td colSpan={2}></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td width={20} style={{textAlign:'left'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>T.</p></td>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8.5pt'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{props.data.telNumber}</p></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td width={20} style={{textAlign:'left'}}>&nbsp;</td>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8.5pt'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{props.data.telNumber2nd}</p></td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td width={20} style={{textAlign:'left'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>M.</p></td>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8.5pt'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{props.data.phoneNumber}</p></td>
					        </tr>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td width={20} style={{textAlign:'left'}}>&nbsp;</td>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8.5pt'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{props.data.phoneNumber2nd}</p></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td width={20} style={{textAlign:'left'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>F.</p></td>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8.5pt'}}><p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>{props.data.faxNumber}</p></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td colspan="2" style={{padding:'0px',color:'rgb(0,0,0)'}}>
					        	<p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'underline',color:color,fontSize:'8.5pt'}}>{props.data.emailAddress}</a></p>
					        	</td>
					        </tr>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td colspan="2" style={{padding:'0px',color:'rgb(0,0,0)'}}>
					        	<p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}><a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'underline',color:color,fontSize:'8.5pt'}}>{props.data.webAddress}</a></p>
					        	</td>
					        </tr>
					        }

					        <tr height={2} style={{lineHeight:'3pt',fontSize:'3.5pt'}}><td colSpan={2}></td></tr>

					        <tr height={15} style={{verticalAlign:'middle',fontFamily: 'Times New Roman,Calibri,Arial'}}>
					        	<td colspan="2" style={{padding:'0px',color:'rgb(0,0,0)'}}>
					        	<p style={{color:color,fontSize:'8.5pt',lineHeight:'11pt',margin:'0px'}}>
						        	<span style={{margin:'0px',color:color,fontSize:'8.5pt'}}>{props.data.officeLocation ? props.data.officeLocation :'Office 3701'}</span>
						        	<span style={{margin:'0px',color:color,fontSize:'8.5pt'}}>{props.data.addressLocation ? ', '+props.data.addressLocation :', West Bay'}</span>
						        	<span style={{margin:'0px',color:color,fontSize:'8.5pt'}}>{props.data.countryLocation ? ', '+props.data.countryLocation :', Doha'}</span>
					        	</p>
					        	</td>
					        </tr>
		  					</tbody>
					    </table>
					</td>
					<td width={20} style={{width:'20px',borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={20} style={{width:'20px',color:'#ffffff'}}>&nbsp;</td>
					<td width={165} style={{maxWidth:'185px',width:'185px',padding:'0px', verticalAlign:'top'}}>
						<img width={120} height={81} src="https://artanholding.com/signature_img/bndesigns/bndesign-logo.png" alt="Portrait" role="presentation" style={{maxWidth:'120', display:'block'}} />
						
				  	</td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colspan={4} height={10} style={{verticalAlign:'top',height:'10px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize:'6.5pt'}}>
					<td colspan={4} style={{verticalAlign:'top'}}>
					<p style={{lineHeight:'9pt',margin: '0px'}}>
						<span style={{fontSize:'6.5pt',fontWeight:'bold',margin:'0',padding:'0',color:'#000000'}}>WARNING: </span>
						<span style={{fontSize:'6.5pt',fontWeight:'normal',color:'#000000',margin:'0',padding:'0'}}>Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. BN Designs accepts no liability for any damage caused by any virus transmitted by this email.</span>
					</p>
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default BnDesign;
