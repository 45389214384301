import React from 'react';

function Gulfcms(props) {
	const color = "#111111"
	return (
		<div className="signature-content" id="signature-content">
			<table width={345} cellPadding={0} cellSpacing={0} style={{maxWidth:'345px',width:'345px',fontFamily:'Arial',lineHeight:'9pt'}}>
		  		<tbody>
				<tr>  			
					<td width={175} style={{maxWidth:'175px',width:'175px',verticalAlign:'top'}}>
						<table width={175} cellPadding={0} cellSpacing={0} style={{maxWidth:'175px',width:'175px',verticalAlign:'top',fontFamily:'Arial',lineHeight:'16px'}}>
					      	<tbody>
					      	<tr height={15} style={{lineHeight:'12pt'}}>
					        	<td>
					        		<p height={15} style={{height:'15px',padding:'0px',margin: '0px',color:color,fontSize:'12pt',fontWeight:'bold',lineHeight:'12pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<p style={{color:color,fontSize:'9pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>{!props.data.position ? 'Position' :props.data.position}</p>
					        	</td>
					        </tr>
					        <tr height={35} style={{lineHeight:'11pt',fontSize:'8pt'}}><td></td></tr>
					      	
					        <tr height={15} style={{verticalAlign:'bottom',fontFamily: 'Arial'}}>
					        	<td width={125} style={{padding:'0px',color:color,fontSize:'8pt'}}>
					        		{ (props.data.phoneNumber) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>Mob: <b>{props.data.phoneNumber}</b></p>
					        		}
					        		{ (props.data.phoneNumber && props.data.phoneNumber2nd) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{props.data.phoneNumber2nd}</b></p>
					        		}
					        		{ (props.data.telNumber) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>Tel: <b>{props.data.telNumber}</b></p>
					        		}
					        		{ (props.data.telNumber && props.data.telNumber2nd) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{props.data.telNumber2nd}</b></p>
					        		}
					        		{ (props.data.faxNumber) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>Fax: <b>{props.data.faxNumber}</b></p>
					        		}
					        		{ (props.data.emailAddress) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>Email: <a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'underline',color:color,fontSize:'8pt',fontFamily:'Arial',lineHeight:'11pt',fontWeight:'bold'}}>{props.data.emailAddress}</a></p>
					        		}
					        		{ (props.data.webAddress) && 
					        			<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>Web: <a href={'http://'+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'underline',color:color,fontSize:'8pt',fontFamily:'Arial',lineHeight:'11pt',fontWeight:'bold'}}>{props.data.webAddress}</a></p>
					        		}
					        		<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px'}}>
						        		<span style={{margin:'0px',color:color,fontSize:'8pt'}}>PO Box: <b>{props.data.poboxLocation ? props.data.poboxLocation :'8720'}</b></span>
					        		</p>
					        		<p style={{color:color,fontSize:'8pt',lineHeight:'11pt',margin:'0px',fontWeight:'bold'}}>
						        		<span style={{margin:'0px',color:color,fontSize:'8pt'}}>{props.data.countryLocation ? props.data.countryLocation :'Doha, Qatar'}</span>
					        		</p>
					        	</td>
					        </tr>
		  					</tbody>
					    </table>
					</td>
					<td width={10} valign="middle" style={{width:'10px',color:'#ffffff',verticalAlign:'middle'}}>
						<img src="https://artanholding.com/signature_img/gulfcms/black-line-1.png" height={160} width={1} alt="line"/>
					</td>
					<td width={10} style={{width:'10px',color:'#ffffff'}}>&nbsp;</td>
					<td width={150} align="right" style={{maxWidth:'185px',width:'185px',padding:'0px', verticalAlign:'top',textAlign:'right'}}>
						<img width={150} height={120} src="https://artanholding.com/signature_img/gulfcms/gulfcms-logo-150-2.png" alt="logo" role="presentation" style={{maxWidth:'150px'}} /><br/>
						<img width={80} height={50} src="https://artanholding.com/signature_img/gulfcms/iso-approved-2023.png" alt="cert logo" role="presentation" style={{maxWidth:'80px'}} />&nbsp;&nbsp;&nbsp;
						
				  	</td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colspan={4} height={40} align="center" style={{textAlign:'center',verticalAlign:'bottom',height:'40px'}}>
					<p style={{lineHeight:'9pt',margin: '0px'}}>
						<b style={{fontSize:'8.5pt',fontWeight:'bold',margin:'0',padding:'0',color:'#000000'}}>Birkat Al Awamer, Logistics Park &lsquo;A&rsquo;</b><br/>
						<img src="https://artanholding.com/signature_img/gulfcms/red-line-350.png" width={345} height={5} alt="Red Line" />
					</p>
					</td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize:'7pt'}}>
					<td colspan={4} style={{verticalAlign:'top'}}>
					<p style={{lineHeight:'9pt',margin: '0px'}}>
						<span style={{fontSize:'7pt',fontWeight:'bold',margin:'0',padding:'0',color:'#000000'}}>WARNING: </span>
						<span style={{fontSize:'7pt',fontWeight:'normal',color:'#000000',margin:'0',padding:'0'}}>Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. GulfCMS accepts no liability for any damage caused by any virus transmitted by this email.</span>
					</p>
					{/*Ramadan Banner
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={345} height={82} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-gcms.png" alt="Eid 2023" />
					*/}	
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Gulfcms;
