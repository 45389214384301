import React from 'react';

function Cc(props) {
	const color = "#1D1D1B"
	const color2 = "#f0b54b"
	const fontBold = "Branding-SemiBold, Arial, sans-serif"
	const font = "Branding-Medium, Arial, sans-serif"
	const tableSize = 590

	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={0} style={{width:'590px',fontFamily:font,lineHeight:'11pt'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>
					<td width={230} style={{textAlign:'left',verticalAlign:'top',lineHeight:'7pt'}}>
						<img width={230} height={114} src="https://artanholding.com/signature_img/citycollege/img/CUCLogo2022.png" alt="logo" style={{width:'230px',maxWidth:'230px',height:'114px',margin: '0',padding: '0'}} />
					</td>
					<td width={20} style={{borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={20} style={{width: '20px',color:'#ffffff'}}>&nbsp;</td>
					<td width={300} style={{padding:'0px',verticalAlign:'top',lineHeight:'11pt'}}>
					    <table width={300} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'8.25pt',fontFamily:font,lineHeight:'11pt'}}>
					      	<tbody>
					      	<tr height={12} style={{lineHeight:'11pt'}}>
					        	<td height={12} colSpan="2" style={{lineHeight:'11pt',fontFamily:font}}>
					        		<p style={{margin:'0px',padding:'0px',color:color,fontSize:'13pt',fontWeight:'bold',fontFamily: fontBold,lineHeight:'13pt'}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<span style={{padding:'0px',color:color,fontSize:'7pt'}}>{!props.data.position ? 'POSITION' :props.data.position}</span>
					        	</td>
					        </tr>
					      	<tr height={15}><td colSpan="2"></td></tr>
					      	{ (props.data.telNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color2,fontSize:'8.25pt',fontFamily:font}}><span>T</span></td>
					        	<td width={269} style={{width:'269px',padding:'0px',color:color,fontSize:'8.25pt',fontFamily:font}}>
					        		<span>{props.data.telNumber}</span>
					        		{ (props.data.telNumber2nd) && 
					        			<span> / {props.data.telNumber2nd}</span>
					        		}
					        	</td>
					        </tr>
					        }
					        { (props.data.phoneNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color2,fontSize:'8.25pt',fontFamily:font}}><span>M</span></td>
					        	<td width={269} style={{width:'269px',padding:'0px',color:color,fontSize:'8.25pt',fontFamily:font}}><span>{props.data.phoneNumber}</span>
					        		{ (props.data.phoneNumber2nd) && 
					        			<span> / {props.data.phoneNumber2nd}</span>
					        		}
					        	</td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color2,fontSize:'8.25pt',fontFamily:font}}><span>F</span></td>
					        	<td width={269} style={{width:'269px',padding:'0px',color:color,fontSize:'8.25pt',fontFamily:font}}><span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	{ (props.data.emailAddress) &&
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td width={15} style={{width:'15px',textAlign:'left',color:color2,fontSize:'8.25pt',fontFamily:font}}><span>E</span></td>
					        	<td width={269} style={{width:'269px',padding:'0px',color:'rgb(0,0,0)'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'8.25pt',fontFamily:font}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					        <tr height={12}>
					        	<td colSpan="2" />
					        </tr>
					        <tr height={15} style={{lineHeight:'11pt'}}>
					        	<td colSpan="2"  width={274} style={{width:'274px',textAlign:'left',color:color,fontSize:'8.25pt',fontFamily:font}}>
					        	<span>{props.data.officeLocation ? props.data.officeLocation :'12th Floor, Excellence Tower, West Bay'} {props.data.poboxLocation ? 'P.O Box ' + props.data.poboxLocation :'P.O Box 200197'}</span>,<br/>
					        	<span>{props.data.countryLocation ? props.data.countryLocation :'Doha, Qatar'}</span>
					        	</td>
					        </tr>
					        <tr height={12}>
					        	<td colSpan="2" />
					        </tr>
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'9pt',fontSize: '6pt'}}>
					<td colSpan={4} style={{verticalAlign:'top',fontFamily:font,borderTop:'1px solid' + color}}>
						<br/>
						<span style={{fontSize:'6pt',color:color,margin:'0',padding:'0'}}>This email, together with any attachments, is intended for the named recipient(s) only and may contain privileged and confidential information. If received in error, please inform the sender as quickly as possible and delete this e-mail and any copies from your computer network. If not an intended recipient of this email, you must not copy, distribute or rely on it and any form of disclosure of the sender and not the views of City University College LLC.<br/><br/>WARNING: Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. City University College LLC accepts no liability for any damage caused by any virus by this email</span>
						
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<a href="https://bit.ly/3NMaKS5" target="_Blank"><img width={600} height={250} src="https://artanholding.com/signature_img/citycollege/banner/cuc-ulster-banner-enroll-now.jpg" alt="Enroll Now!" /></a>
					{/*
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={600} height={143} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-cuc.png" alt="Eid 2023" />
					*/}
					</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Cc;
