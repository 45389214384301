import React from 'react';

function Primepower(props) {
	const color = "#111111"
	const font = "Arial, sans-serif"
	const tableSize = 525
	return (
		<div className="signature-content" id="signature-content">
			<table width={tableSize} cellPadding={0} cellSpacing={5} style={{width:tableSize+'px',fontFamily:font,lineHeight:'11pt',background:'#ffffff'}}>
		  		<tbody>
				<tr style={{lineHeight:'11pt'}}>  			
					<td width={150} style={{verticalAlign:'top',lineHeight:'11pt'}}>
						<p style={{lineHeight:'3pt',textAlign:'center',padding:'0',margin:'0',paddingTop:'0',paddingBottom:'0',fontSize: '3pt'}}><img width={150} height={50} src="https://artanholding.com/signature_img/primepower/img/2024/pp-logo.png" alt="logo" role="presentation" style={{width:'150px',maxWidth:'150px',height:'50px',margin:'0',padding:'0'}} /></p>
						<p style={{fontSize:'2pt',margin:'0px',padding:'0px',height:'3px',lineHeight:'2pt'}}>&nbsp;</p>
						<p style={{lineHeight:'11pt',textAlign:'center',padding:'0',margin:'0',paddingTop: '0',paddingBottom: '0'}}><img width={140} height={75} src="https://artanholding.com/signature_img/primepower/img/2024/stamp-iso.png" alt="cert" role="presentation" style={{width:'140px',maxWidth:'140px',height:'75px',margin:'0px',padding:'0'}}/></p>
						<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
						<p style={{lineHeight:'11pt',textAlign:'center',padding:'0',margin:'0',paddingTop: '0',paddingBottom: '0'}}><img width={140} height={75} src="https://artanholding.com/signature_img/primepower/img/2024/stamp-icv.png" alt="cert" role="presentation" style={{width:'140px',maxWidth:'140px',height:'75px',margin:'0px',padding:'0'}}/></p>
						<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					</td>
					<td width={10} style={{borderRight:'solid 1px '+color,color:'#ffffff'}}>&nbsp;</td>
					<td width={10} style={{width:'10px',color:'#ffffff'}}>&nbsp;</td>
					<td width={355} style={{padding:'0px',verticalAlign:'top'}}>
					    <table width={355} cellPadding={0} cellSpacing={0} style={{verticalAlign:'top',fontSize:'8.5pt',fontFamily:font}}>
					      	<tbody>
					      	<tr height={18} style={{lineHeight:'12pt'}}>
					        	<td colSpan="2">
					        		<p style={{lineHeight:'12pt',fontSize:'10.5pt',margin: '0px',color:color,fontWeight:'600',fontFamily:font}}>{!props.data.firstName ? 'First' :props.data.firstName} {!props.data.lastName ? 'Last' :props.data.lastName}</p>
					        		<p style={{lineHeight:'12pt',fontSize:'9.5pt',margin: '0px',color:color,fontFamily:font}}>{!props.data.position ? 'Position' :props.data.position}</p>
					        	</td>
					        </tr>
					      	<tr height={10} style={{lineHeight:'11pt',fontSize:'8pt'}}><td></td></tr>
					      	
					        { (props.data.phoneNumber) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-phone.png" alt="Phone icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>{props.data.phoneNumber}</span></td>
					        </tr>
					        }
					        { (props.data.phoneNumber && props.data.phoneNumber2nd) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}>&nbsp;</td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>{props.data.phoneNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.telNumber) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-tel.png" alt="Tel icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>{props.data.telNumber}</span></td>
					        </tr>
					        }
					      	{ (props.data.telNumber && props.data.telNumber2nd) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20} style={{textAlign:'center',color:'#fff'}}>&nbsp;</td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>{props.data.telNumber2nd}</span></td>
					        </tr>
					        }
					        { (props.data.faxNumber) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-printer.png" alt="Fax icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>{props.data.faxNumber}</span></td>
					        </tr>
					    	}
					    	<tr height={5} style={{lineHeight:'5pt',fontSize:'5pt'}}><td></td></tr>
					        { (props.data.supportNumber) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-phone.png" alt="Phone icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'9.5pt',color:'#e60019',fontFamily:font}}><span><b style={{fontWeight:'600'}}>{props.data.supportNumber} (24/7 Support)</b></span></td>
					        </tr>
					        }
					    	<tr height={5} style={{lineHeight:'5pt',fontSize:'5pt'}}><td></td></tr>
					    	{ (props.data.emailAddress) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-email.png" alt="Email icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"mailto:"+props.data.emailAddress} style={{textDecoration:'none',color:color,fontSize:'8pt',fontFamily:font}}>{props.data.emailAddress}</a>
					        	</td>
					        </tr>
					    	}
					    	{ (props.data.webAddress) &&
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}>&nbsp;</td>
					        	<td width={335} style={{padding:'0px',color:'rgb(0,0,0)'}}><a href={"http://"+props.data.webAddress} target="_Blank" rel="noopener noreferrer" style={{textDecoration:'none',color:color,fontSize:'8pt',fontWeight:'600',fontFamily:font}}>{props.data.webAddress}</a></td>
					        </tr>
					        }
					        <tr height={5} style={{lineHeight:'5pt',fontSize:'5pt'}}><td></td></tr>
					        <tr height={18} style={{lineHeight:'11pt'}} >
					        	<td width={20} style={{verticalAlign:'top'}}><img width={12} height={12} src="https://artanholding.com/signature_img/primepower/img/2024/icon-location.png" alt="Location icon" style={{width:'12px'}} /></td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>Zone 71, Street No. 1262, Jery Al Samur Logistic Park Manateq Umm Salal Ali.</span></td>
					        </tr>
					        <tr height={18} style={{lineHeight:'11pt'}}>
					        	<td width={20}>&nbsp;</td>
					        	<td width={335} style={{padding:'0px',color:color,fontSize:'8pt',fontFamily:font}}><span>P.O. Box 25525 – Qatar</span></td>
					        </tr>
		  					</tbody>
					    </table>
				  </td>
				</tr>
				<tr style={{lineHeight:'0px'}}>
					<td colSpan={4} height={5} style={{verticalAlign:'top',height:'5px'}}>
					</td>
				</tr>
				<tr style={{lineHeight:'11pt',fontSize:'7pt'}}>
					<td colSpan={4} style={{verticalAlign:'top'}}>
					
						<a href="https://maps.app.goo.gl/L8D8U7rm22hSxbNd8" target="_blank" style={{textDecoration:'none',color:color,fontSize:'8pt',fontFamily:font}}><img width={525} height={87} src="https://artanholding.com/signature_img/primepower/img/2024/ppme-location-banner.png" alt="PPME Location" /></a>
						<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
						<p style={{lineHeight:'11pt',margin: '0px',fontFamily:font}}>
							<span style={{fontSize:'10pt',fontStyle:'normal',margin:'0',color:'#e60019',lineHeight:'11pt',fontWeight:'bold'}}>&ldquo;Proud to be a 100% Qatari Company&rdquo;</span><br/><br/>
							<span style={{fontSize:'7pt',margin:'0',color:color}}>Save a tree!!! Please consider the environment before printing this email!</span><br/><br/>
							<span style={{fontSize:'7pt',fontWeight:'normal',color:color,margin:'0',padding:'0'}}><b style={{color:'#e60019'}}>WARNING:</b> Computer viruses can be transmitted via email. The recipient should check this email and any attachments for the presence of viruses. Primepower accepts no liability for any damage caused by any virus transmitted by this email.</span>
						</p>
						{/*Ramadan Banner
					<p style={{fontSize:'6pt',margin:'0px',padding:'0px',height:'10px',lineHeight:'8pt'}}>&nbsp;</p>
					<img width={400} height={95} src="https://artanholding.com/signature_img/eid/2023/eid-adha-2023-email-banner-ppme.png" alt="Eid 2023" />
					*/}</td>
				</tr>
		  		</tbody>
			</table>
		</div>
	);
}

export default Primepower;
