import ArtanHolding from './signature/ArtanHolding'
import Hai from './signature/Hai'
import Dbs from './signature/Dbs'
import Dbsak from './signature/Dbsak'
import Dbswk from './signature/Dbswk'
import Dbsrw from './signature/Dbsrw'
import Cc from './signature/Cc'
import UlsterUniversity from './signature/UlsterUniversity'
import BnDesign from './signature/BnDesign'
import Qsa from './signature/Qsa'
import Bnitc from './signature/Bnitc'
import Aces from './signature/Aces'
import Primepower from './signature/Primepower'
import Gulfcms from './signature/Gulfcms'
import Mirage from './signature/Mirage'
import MirageFH from './signature/MirageFoxHills'
import GarafahCleaning from './signature/GarafahCleaning'


const CompanyData = [
	{
		code : "ah",
		signatureComponent : ArtanHolding,
		webAddress: "www.artanholding.com",
		faxNumber: "+974 4412 3799",
		telNumber: "+974 0000 0000",
	},
	{
		code : "dbs",
		signatureComponent : Dbs,
		webAddress: "www.dohabritishschool.com",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "dbsak",
		signatureComponent : Dbsak,
		webAddress: "www.dohabritishschool.com",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "dbswk",
		signatureComponent : Dbswk,
		webAddress: "www.dbswakra.com",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "dbsrw",
		signatureComponent : Dbsrw,
		webAddress: "www.dbsrawdat.com",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "hai",
		signatureComponent : Hai,
		webAddress: "www.hai-artan.com",
		faxNumber: "+974 4482 0224",
		telNumber: "+974 0000 0000",
	},
	{
		code : "cc",
		signatureComponent : Cc,
		webAddress: "www.cuc-ulster.edu.qa",
		faxNumber: "+974 4416 2637",
		telNumber: "+974 0000 0000",
	},
	{
		code : "bnd",
		signatureComponent : BnDesign,
		webAddress: "www.bn-designs.com",
		faxNumber: "+974 4412 3799",
		telNumber: "+974 0000 0000",
	},
	{
		code : "qsa",
		signatureComponent : Qsa,
		webAddress: "www.qatarskills.com.qa",
		faxNumber: "+974 4416 2637",
		telNumber: "+974 4416 1622",
		telNumber2nd: "+974 4019 8165",
	},
	{
		code : "bnitc",
		signatureComponent : Bnitc,
		webAddress: "www.bnitcqatar.com",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "aces",
		signatureComponent : Aces,
		webAddress: "www.aces.qa",
		faxNumber: "",
		telNumber: "+974 0000 0000",
	},
	{
		code : "gulfcms",
		signatureComponent : Gulfcms,
		webAddress: "www.gulfcms.com",
		faxNumber: "+974 4469 1299",
		telNumber: "+974 0000 0000",
	},
	{
		code : "primepower",
		signatureComponent : Primepower,
		webAddress: "www.primepowerme.com",
		faxNumber: "+974 4436 6252",
		telNumber: "+974 4436 0610",
		supportNumber: "+974 5580 5474",
		poboxLocation: "25525"
	},
	{
		code : "mipc",
		signatureComponent : Mirage,
		webAddress: "www.mirageproperty.com",
		faxNumber: "+974 4431 0408",
		telNumber: "+974 0000 0000",
		poboxLocation: "22621"
	},
	{
		code : "mipcfh",
		signatureComponent : MirageFH,
		webAddress: "www.mirageproperty.com",
		faxNumber: "+974 4431 0408",
		telNumber: "+974 0000 0000",
		poboxLocation: "22621"
	},
	{
		code : "uu",
		signatureComponent : UlsterUniversity,
		webAddress: "www.ulsteruniversity.qa",
		faxNumber: ""
	},
	{
		code : "gc",
		signatureComponent : GarafahCleaning,
		webAddress: "www.algarafahcleaning.com",
		faxNumber: "+974 4412 3799",
		telNumber: "+974 4403 8666",
		poboxLocation: "22621"
	}
]


export default CompanyData